<template>
<div class="report-area">
    <div class="vx-row">
        <div class="vx-col w-full">
            <h1></h1>
            <div id="reportViewer1">loading...</div>
        </div>
    </div>
</div>
</template>

<script>
//import "../assets/ReportViewer/js/telerikReportViewer.kendo-13.2.19.1030.min.js"
import "../assets/ReportViewer/js/telerikReportViewer-13.2.19.1030.min.js";

export default {
    name: "ReportViewer",
    data() {
        return {
            reportData: null
        };
    },
    mounted() {
        this.getReportData();
        let reportname = this.$route.params.reportname;
        setTimeout(() => {
            let menuitem = [];
            menuitem = document.getElementsByClassName("vs-sidebar-group ");
            menuitem.forEach(element => {
                let arrow = element.getElementsByClassName(
                    "feather-icon select-none relative feather-grp-header-arrow"
                );
                let text = element.getElementsByClassName(
                    "truncate mr-3 select-none"
                )[0].innerText;
                let uls = element.getElementsByTagName("ul");
                if (
                    reportname == "VisitRawDataReport" ||
                    reportname == "VisitDetailReport" ||
                    reportname == "SearchEngineReport" ||
                    reportname == "SearchTermReport"
                ) {
                    if (text == "Leads") {
                        element.className += " vs-sidebar-group-open";
                        arrow[0].className += " rotate90";
                        uls[0].style.maxHeight = "none";
                    }
                } else {
                    if (text == "Call Tracking") {
                        element.className += " vs-sidebar-group-open";
                        arrow[0].className += " rotate90";
                        uls[0].style.maxHeight = "none";
                    }
                }
            });
        }, 300);
    },
    beforeRouteLeave(to, from, next) {
        let reportname = this.$route.params.reportname;
        setTimeout(() => {
            let menuitem = [];
            menuitem = document.getElementsByClassName("vs-sidebar-group ");
            menuitem.forEach(element => {
                if (element.classList.contains("vs-sidebar-group-open")) {
                    let text = element.getElementsByClassName(
                        "truncate mr-3 select-none"
                    )[0].innerText;
                    let uls = element.getElementsByTagName("ul");
                    let arrow = element.getElementsByClassName(
                        "feather-icon select-none relative feather-grp-header-arrow"
                    );
                    let det = to.path;
                    if (
                        reportname == "VisitRawDataReport" ||
                        reportname == "VisitDetailReport" ||
                        reportname == "SearchEngineReport" ||
                        reportname == "SearchTermReport"
                    ) {
                        det = det.match("/leads");
                        if (text == "Leads" && !det) {
                            uls[0].style.maxHeight = 0;
                            arrow[0].classList.remove("rotate90");
                            element.classList.remove("vs-sidebar-group-open");
                        }
                    } else {
                        det = det.match("/call-tracking");
                        if (text == "Call Tracking" && !det) {
                            uls[0].style.maxHeight = 0;
                            arrow[0].classList.remove("rotate90");
                            element.classList.remove("vs-sidebar-group-open");
                        }
                    }
                }
            });
        }, 300);
        next();
    },

    /* beforeMount() {
           window.addEventListener("beforeunload", this.preventNav);
       },*/
    methods: {
        /* preventNav($event) {
                 console.log("dfvf");
                 console.log($event);
             },*/

        async getReportData() {
            this.$vs.loading();
            await this.axios
                .get("ws/MyReports/GetMyAvailableReports")
                .then(response => {
                    let data = response.data;
                    this.reportData = data;
                    let uid = this.$route.params.id;
                    let selectedData = null;
                    let reportPara = {};

                    this.reportData.forEach(element => {
                        let reportName = element.Report_Name;
                        reportName = reportName.replace(/\s/g, "");
                        if (reportName == this.$route.params.reportname) {
                            selectedData = element;
                            selectedData.Report_Parameters.forEach(el => {
                                reportPara[el.Key] = el.Value;
                            });
                        }
                    });
                    let that = this;

                    window.jQuery("#reportViewer1").telerik_ReportViewer({
                        authenticationToken: that.$store.state.authTokenVV[uid].token,
                        serviceUrl: "https://" +
                            selectedData.Report_Server +
                            selectedData.Report_Endpoint,
                        reportSource: {
                            report: selectedData.Report_File_Name,
                            parameters: reportPara
                            // {
                            //   paramOrg: "b00835049795267425010000",
                            //   paramCustomerId: "b50835049795267425020000",
                            //   paramCompanyName: "Visual Visitor",
                            //   paramTimeZone: "Eastern Standard Time",
                            //   paramStartDate: "6/22/2020",
                            //   paramEndDate: "6/29/2020"
                            // } //loop through Report_Parameters key value pairs to create
                        },
                        viewMode: this.$route.params.reportname == "VisitRawDataReport" ||
                            this.$route.params.reportname == "CallTrackingRawDataReport" ?
                            "" : "PRINT_PREVIEW",
                        scaleMode: "SPECIFIC",
                        scale: 1.0,
                        sendEmail: {
                            enabled: false
                        }
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
body {
    font-family: Verdana, Arial;
    margin: 5px;
}

#reportViewer1 {
    position: absolute;
    top: 70px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    overflow: hidden;
    clear: both;
}
</style>
